<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="130px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item label="备注名">
      <el-input
        style="width:500px;"
        v-model="formModel.name"
        placeholder="请输入备注名...(例:七里香店)"
      >
      </el-input>
    </el-form-item>

    <el-form-item label="编号">
      <el-input
        style="width:300px;"
        v-model="formModel.number"
        placeholder="请输入编号...(例:9527)"
      >
      </el-input>
    </el-form-item>

    <el-form-item label="POS所在店铺">
      <el-select
        v-model="model.store_id"
        placeholder="请选择"
      >
        <el-option
          v-for="item in model.shops"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>

    </el-form-item>

    <el-form-item label="状态">
      <el-radio-group v-model="model.status">
        <el-radio :label="0">禁用</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
       <code style="color:#909399;">(禁用时，该POS机无法进行收银支付操作) </code>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        size="medium"
        native-type="submit"
        :loading="submitLoading"
      >{{isEdit === true?'保存':'新建'}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'FormPos',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formModel: null,
      formRules: null
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.model)
  },
  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
