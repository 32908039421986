import Vue from 'vue'

const PosService = {
  all (param) {
    return Vue.http.get('pos/index', param)
  },
  delete (id) {
    return Vue.http.delete('pos/delete', id)
  },

  edit (id, model = null) {
    const endpoint = 'pos/update'

    if (model === null) {
      return Vue.http.get(endpoint, {
        id: id
      })
    }

    return Vue.http.put(endpoint, model, {
      id: id
    })
  },
  create (model = null) {
    if (model === null) {
      return Vue.http.get('pos/create')
    }
    return Vue.http.post('pos/create', model)
  },
  createSetting (model = null) {
    if (model === null) {
      return Vue.http.get('pos/setting')
    }
    return Vue.http.post('pos/setting', model)
  },
  createReasonLabel (model = null) {
    const endpoint = 'pos/label'
    if (model === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, model)
  },
  deleteReasonLabel (param) {
    return Vue.http.delete('pos/label', param)
  }

}

export default PosService
