<template>
   <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <pos-form v-if="model"
        :submit-text="pageTitle"
        :model="model"
        :isEdit="true"
        @on-submit="editModel">
    </pos-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import PosForm from './components/_Form'
import PosService from '@admin/services/PosService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  name: 'PosEdit',
  components: { PlaceholderForm, PosForm },
  data () {
    return {
      pageTitle: '编辑POS机',
      model: null
    }
  },
  async created () {
    const { data } = await flatry(PosService.edit(this.$router.currentRoute.query.id))
    if (data) {
      this.model = data.data
    }
  },
  methods: {
    async editModel (model, success, callback) {
      const { data } = await flatry(PosService.edit(model.id, model))

      if (data) {
        this.$message.success(data.msg)

        this.$router.replace({ path: '/pos/update', query: { id: data.data } })

        success()
      }

      callback()
    }
  }

}

</script>
<style lang='' scoped>

</style>
